export const CACHE_PAGE = 'CACHE_PAGE'
export const FETCH_PAGE = 'FETCH_PAGE'
export const DELETE_PAGE = 'DELETE_PAGE'
export const ARCHIVE_PAGE = 'ARCHIVE_PAGE'
export const SET_DEFAULT_PAGE = 'SET_DEFAULT_PAGE'
export const PATCH = 'PATCH'
export const REMOVE_PAGE_FROM_CACHE = 'REMOVE_PAGE_FROM_CACHE'
export const SET_PAGE_PUBLIC = 'SET_PAGE_PUBLIC'
export const CACHE_PUBLIC_PAGE = 'CACHE_PUBLIC_PAGE'
export const SET_FOCUS_INDEX = 'SET_FOCUS_INDEX'
export const CACHE_SHARED_WITH_GROUPS = 'CACHE_SHARED_WITH_GROUPS'
