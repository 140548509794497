// list built from Zotero data
export const PublicationTypeId = {
  ARTWORK: 'artwork',
  ATTACHMENT: 'attachment',
  AUDIO_RECORDING: 'audioRecording',
  BILL: 'bill',
  BLOG_POST: 'blogPost',
  BOOK_SECTION: 'bookSection',
  BOOK: 'book',
  CASE: 'case',
  COMPUTER_PROGRAM: 'computerProgram',
  CONFERENCE_PAPER: 'conferencePaper',
  DICTIONARY_ENTRY: 'dictionaryEntry',
  DOCUMENT: 'document',
  EMAIL: 'email',
  ENCYCLOPEDIA_ARTICLE: 'encyclopediaArticle',
  FILM: 'film',
  FORUM_POST: 'forumPost',
  HEARING: 'hearing',
  INSTANT_MESSAGE: 'instantMessage',
  INTERVIEW: 'interview',
  JOURNAL_ARTICLE: 'journalArticle',
  LETTER: 'letter',
  MAGAZINE_ARTICLE: 'magazineArticle',
  MANUSCRIPT: 'manuscript',
  MAP: 'map',
  NEWSPAPER_ARTICLE: 'newspaperArticle',
  NOTE: 'note',
  PATENT: 'patent',
  PODCAST: 'podcast',
  PRESENTATION: 'presentation',
  RADIO_BROADCAST: 'radioBroadcast',
  REPORT: 'report',
  STATUTE: 'statute',
  THESIS: 'thesis',
  TV_BROADCAST: 'tvBroadcast',
  VIDEO_RECORDING: 'videoRecording',
  WEBPAGE: 'webpage',
}
