export const FETCH_SESSION = 'FETCH_SESSION'
export const CACHE_SESSION = 'CACHE_SESSION'
export const DENY_ACCESS = 'DENY_ACCESS'
export const REQUEST_CODE = 'REQUEST_CODE'
export const END_SESSION = 'END_SESSION'
export const CACHE_PUBLIC_SESSION = 'CACHE_PUBLIC_SESSION'
export const GET_USER_ACCOUNT = 'GET_USER_ACCOUNT'
export const CACHE_USER_ACCOUNT = 'CACHE_USER_ACCOUNT'
export const LOGOUT = 'LOGOUT'
export const SET_DEFAULT_PAGE = 'SET_DEFAULT_PAGE'
export const SET_SESSION = 'SET_SESSION'
export const SET_READ_ONLY = 'SET_READ_ONLY'
export const STORE_SESSION_LOCALLY = 'STORE_SESSION_LOCALLY'
