export const SET_BLOCKS = 'SET_BLOCKS'
export const SET_SELECTION = 'SET_SELECTION'
export const SPLIT = 'SPLIT'
export const INSERT_BEFORE = 'INSERT_BEFORE'
export const MERGE = 'MERGE'
export const SET_CONTENT = 'SET_CONTENT'
export const REMOVE = 'REMOVE'
export const REMOVE_AT_SELECTION = 'REMOVE_AT_SELECTION'
export const CLEAR = 'CLEAR'
export const DEQUEUE_NEW_ENTITY = 'DEQUEUE_NEW_ENTITY'
export const COPY = 'COPY'
export const CUT = 'CUT'
export const PASTE = 'PASTE'
export const PASTE_EMBED = 'PASTE_EMBED'
export const UNDO = 'UNDO'
export const REDO = 'REDO'
export const CACHE_ENTITY_SUGGESTIONS = 'CACHE_ENTITY_SUGGESTIONS'
export const DEQUEUE_REMOVED_ENTITY = 'DEQUEUE_REMOVED_ENTITY'
